@import '../../scss/style.scss';


.hero-wrap{
	width: 100%;
	height: 850px;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	z-index: 0;
	@include media-breakpoint-down(md){
		background-position: top center !important;
	}
	.overlay{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		background: rgba($black, .8);
		height: 850px;
	}
	&.hero-wrap-2 {
		height: 500px !important;
		position: relative;
		.overlay{
			width: 100%;
			opacity: .8;
			height: 500px;
		}
		.slider-text{
			height: 500px !important;
		}
	}
	&.hero-wrap-3 {
		height: 400px !important;
		position: relative;
		.overlay{
			width: 100%;
			opacity: .8;
			height: 400px;
		}
		.slider-text{
			height: 400px !important;
		}
	}
	&.hero-wrap-4 {
		height: 300px !important;
		position: relative;
		.overlay{
			width: 100%;
			opacity: .8;
			height: 300px;
		}
		.slider-text{
			height: 300px !important;
		}
	}
	&.hero-wrap-5{
		height: 165px !important;
		position: relative;
		.overlay{
			width: 100%;
			opacity: .8;
			height: 165px;
		}
		.slider-text{
			height: 165px !important;
		}
	}
}

.text-primary.mls.sold::after {
    content: "\aSOLD!";
    position:relative;
    white-space: pre;
    font-weight: bold;
    text-align: center;
    color: white;
    background-color: #ff0000;
}


.text-primary.mls.vendu::after {
    content: "\aVENDU!";
    position:relative;
    white-space: pre;
    font-weight: bold;
    text-align: center;
    color: white;
    background-color: #ff0000;
}

.text-primary.mls.rented::after {
    content: "\aRENTED!";
    position:relative;
    white-space: pre;
    font-weight: bold;
    text-align: center;
    color: white;
    background-color: #ff0000;
}

.text-primary.mls.loue::after {
    content: "\aLOUÉ!";
    position:relative;
    white-space: pre;
    font-weight: bold;
    text-align: center;
    color: white;
    background-color: #ff0000;
}

