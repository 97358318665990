
.homeCard {
    width: 356px;
    height: 267px;

}
.home-price {
    width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 1.5rem;
    white-space:nowrap;
}
.home-address{
    overflow:hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.5rem;
    white-space:nowrap;
    text-overflow: ellipsis;
    width:fit-content;

}

.item.media-homeCard.sold::after {
    content: "SOLD!";
    position:absolute;
    top: 0px;
    left: 15px;
    white-space: pre;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
    color: white;
    background-color: #ff0000;
}
.item.media-homeCard.vendu::after {
    content: "VENDU!";
    position:absolute;
    top: 0px;
    left: 15px;
    white-space: pre;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
    color: white;
    background-color: #ff0000;
}

.item.media-homeCard.rented::after {
    content: "RENTED!";
    position:absolute;
    top: 0px;
    left: 15px;
    white-space: pre;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
    color: white;
    background-color: #ff0000; // Pantone red : ef3340 or f32837?
}

.item.media-homeCard.loue::after {
    content: "LOUÉ!";
    position:absolute;
    top: 0px;
    left: 15px;
    white-space: pre;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
    color: white;
    background-color: #ff0000;
}
