.listing {
    width: 356px;
    height: 267px;

}

.item.media-38289.sold::after {
    content: "SOLD!";
    position:absolute;
    top: 0px;
    left: 0px;
    white-space: pre;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
    color: white;
    background-color: #ff0000;
}

.item.media-38289.vendu::after {
    content: "VENDU!";
    position:absolute;
    top: 0px;
    left: 0px;
    white-space: pre;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
    color: white;
    background-color: #ff0000;
}

.item.media-38289.rented::after {
    content: "RENTED!";
    position:absolute;
    top: 0px;
    left: 0px;
    white-space: pre;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
    color: white;
    background-color: #ff0000;
}

.item.media-38289.loue::after {
    content: "LOUÉ!";
    position:absolute;
    top: 0px;
    left: 0px;
    white-space: pre;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
    color: white;
    background-color: #ff0000;
}



