/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');
/* Boostrap */
@import './bootstrap/bootstrap.scss';
@import './bootstrap/mixins';
/* Base */
@import './_site-base.scss';
/* Navbar */
@import './_site-navbar.scss';
/* Blocks */
@import './_site-blocks.scss';
/* Extra */
@import './_background-header.scss';
/* Owl Carousel */
@import './owl/owl.carousel.scss';
/* Image Gallery */
@import '~react-image-gallery/styles/scss/image-gallery.scss';