.backgroundImg1 {
    background-image: url("../assets/images/landing@0.5x.jpg");

}

.backgroundImg2 {
    background-image: url("../assets/images/interior@0.5x.jpg");
}

.backgroundImg3 {
    background-image: url("../assets/images/0414_gorini@0.5x.jpg");
}

.backgroundImg4 {
    background-image: url("../assets/images/0814_gorini@0.5x.jpg");
}

.backgroundImg5 {
    background-image: url("../assets/images/0279_gorini@0.5x.jpg");
}

// .backgroundImg6 {
//     background-image: url("../assets/images/0733_gorini@0.5x.jpg");
// }

.backgroundImg6 {
    background-image: url("../assets/images/4830_gorini@0.5x.jpg");
}

.backgroundImg7 {
    background-image: url("../assets/images/3553_gproulx@0.5x.jpg");
}

.backgroundImg8 {
    background-image: url("../assets/images/heroProperties2.jpg");
}
.backgroundImg9 {
    background-image: url("../assets/images/heroProperties3@400px.jpg");
}
.backgroundImg10 {
    background-image: url("../assets/images/heroProperties4@308px.jpg");
}
.backgroundImg11 {
    background-image: url("../assets/images/heroProperties5@408px.jpg");
}
