
.modal-dark-background .modal-content {
    background-color: #343A40 !important;
    color: #ffffff;

}

.modal.in .modal-dialog {
    position: fixed;       
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
 }