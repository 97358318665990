// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: $nav-link-padding-y $nav-link-padding-x;

  @include hover-focus {
    text-decoration: none;
  }

  // Disabled state lightens text
  &.disabled {
    color: $nav-link-disabled-color;
    pointer-events: none;
    cursor: default;
  }
}

//
// Tabs
//

.nav-tabs {
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

  .nav-item {
    margin-bottom: -$nav-tabs-border-width;
  }

  .nav-link {
    border: $nav-tabs-border-width solid transparent;
    @include border-top-radius($nav-tabs-border-radius);

    @include hover-focus {
      border-color: $nav-tabs-link-hover-border-color;
    }

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-tabs-link-active-color;
    background-color: $nav-tabs-link-active-bg;
    border-color: $nav-tabs-link-active-border-color;
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}


//
// Pills
//

// .nav-pills {
//   .nav-link {
//     @include border-radius($nav-pills-border-radius);
//   }

//   .nav-link.active,
//   .show > .nav-link {
//     color: $nav-pills-link-active-color;
//     background-color: $nav-pills-link-active-bg;
//   }
// }
//TABULATION
.nav-pills{
	font-size: 18px;
	font-weight: 400;
	display: inline-block;
	li{
		display: inline-block;
		a{
			margin-right: 2px;
			color: rgba(0,0,0,.4) !important;
			&.active{
				color: $black !important;
				background: transparent !important;
			}
		}
	}
}
.pills{
	margin-top: 20px;
	// margin-top: 40px;
	.tab-pane{
		padding: 2em;
		border-top: 1px solid rgba(0,0,0,.1);
	}
	.features{
		margin: 0;
		padding: 0;
		li{
			list-style: none;
			span{
				font-size: 20px;
				margin-right: 15px;
			}
			&.check{
				span{
					color: green;
					font-size: 14px;
				}
			}
			&.remove{
				span{
					color: red;
				}
			}
		}
	}
	.head{
		font-size: 18px;
		margin-bottom: 30px;
		font-weight: 600;
	}
	.review{
		width: 100%;
		margin-bottom: 30px;
		.user-img{
			width: 80px;
			height: 80px;
			@include border-radius(50%);
		}
		.desc{
			width: calc(100% - 100px);
			margin-left: 30px;
			h4{
				width: 100%;
				margin-bottom: 10px;
				span{
					width: 49%;
					display: inline-block;
					&:first-child{
						font-size: 16px;
					}
					&:last-child{
						font-size: 13px;
						color: lighten($black,70%);
					}
				}
			}
			.star{
				width: 100%;
				span{
					width: 49%;
					display: inline-block;
				}
				i{
					color: $secondary;
				}
				.reply{
					padding: 2px 10px;
					background: lighten($black,94%);
					@include border-radius(2px);
					i{
						color: lighten($black,50%);
						font-size: 12px;
					}
				}
			}
		}
	}
	.wrap{
		padding: 2em;
		background: lighten($black,98%);
	}
	.rating-wrap{
		width: 100%;
		display: block;
		.star{
			display: block;
			width: 100%;
			&:last-child{
				margin-bottom: 0;
			}
			span{
				display: inline-block;
				color: lighten($black,70%);
				i{
					color: $primary;
				}
				&:first-child{
					width: 62%;
				}
				&:last-child{
					width: 32%;
				}
			}
		}
	}
}


//
// Justified variants
//

.nav-fill {
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}
