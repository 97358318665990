.property-details{
	.img{
		width: 100%;
		height: 600px;
		margin-bottom: 40px;
		position: relative;
	}
	h2{
		font-weight: 600;
    font-size: 1.5rem;
	}
	span.subheading{
		font-size: 12px;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 2px;
		color: rgba(0,0,0,.4);
		display: block;
	}
}

.fade {
    -moz-transition:    all opacity .15s linear ease;
    -o-transition:      all opacity .15s linear ease;
    -webkit-transition: all opacity .15s linear ease;
    -ms-transition: 		all opacity .15s linear ease;
    transition:         all opacity .15s linear ease;
  
    &:not(.show) {
      opacity: 0;
    }
  }
  
  .collapse {
    &:not(.show) {
      display: none;
    }
  }
  
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -moz-transition:    all opacity .15s linear-collapse ease;
    -o-transition:      all opacity .15s linear-collapse ease;
    -webkit-transition: all opacity .15s linear-collapse ease;
    -ms-transition: 		all opacity .15s linear-collapse ease;
    transition:         all opacity .15s linear-collapse ease;
  }

  .broker {
    @media (min-width:768px) {
      display: none;
    }
    @media (min-width:320px) {
      margin-top: 8px;
    }
  }

  .text-primary.sold::after {
    content: "SOLD!";
    position:relative;
    left: 5px;
    white-space: pre;
    font-weight: bold;
    text-align: center;
    color: white;
    background-color: #ff0000;
}


.text-primary.vendu::after {
  content: "VENDU!";
  position:relative;
  left: 5px;
  white-space: pre;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: #ff0000;
}

.text-primary.rented::after {
  content: "RENTED!";
  position:relative;
  left: 5px;
  white-space: pre;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: #ff0000;
}

.text-primary.loue::after {
  content: "LOUÉ!";
  position:relative;
  left: 5px;
  white-space: pre;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: #ff0000;
}
