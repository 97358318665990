.our-team {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.description {
    height: 48px;
    width: 100%;
    margin-bottom: 24px;
}

.person-29381 .media-39912::before {
    margin: 12px;
    width: 174px !important;
}